import React, { FC } from 'react';

import styled from 'styled-components';
import { smMobile, tablet } from 'styles/breakpoints';
import { Reviews } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import BannerOne from '../BannerOne';
import DogPawList from '../DogPawList';

interface UpsellBundleProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${tablet} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;

const TextContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Headline = styled.p`
  max-width: 629px;
  color: #02b96f;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  margin: 1rem 0;
  @media ${tablet} {
    margin-bottom: 0.5rem 0;
    font-size: 1.25rem;
    max-width: 21.4375rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const Label = styled.p`
  max-width: 768px;
  text-align: center;
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  margin-bottom: 1.5rem;
  @media ${tablet} {
    margin-bottom: 1rem;
    font-size: 1rem;
    max-width: 21.4375rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const TitleContainer = styled.div`
  background-color: #e4e4ef;
  width: 100%;
`;

const Title = styled(Headline)`
  color: #1c1c28;
  margin: 1.5rem auto;
`;

const ReviewContainer = styled.div`
  padding: 4rem 1rem;
  width: 100%;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const PaddingHelper = styled.div`
  background-color: #f6f4ef;
  height: 1.5rem;
  width: 100%;
`;

const UpsellBundle: FC<UpsellBundleProps> = ({
  bannerOne,
  headline,
  label,
  planSection,
  PlanSectionComponent,
  title,
  planSection2,
  userHasBoughtPaperbackBook,
  paymentSteps,
  reviewsSection,
  advancedTricks,
  dogCare,
  homeCooking,
  dogFamous,
}) => (
  <>
    <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
    <PaymentStepsContainer>
      <PaymentStepsStyled
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />
    </PaymentStepsContainer>
    <TextContainer>
      <Headline>{headline}</Headline>
      <Label>{label}</Label>
    </TextContainer>

    <PlanSectionComponent
      {...planSection}
      offColor="#02B96F"
      buttonColor="bundleGreen"
      nameMargin="0rem 0 0.75rem 0"
    />
    <TitleContainer>
      <Title>{title}</Title>
    </TitleContainer>
    <DogPawList
      {...advancedTricks}
      bgColor="#E4E4EF"
      highlightColor="#38724F"
      variant="bone"
    />
    <DogPawList
      {...dogCare}
      bgColor="#E7F6FF"
      highlightColor="#1A90B5"
      variant="paw"
    />
    <DogPawList
      {...homeCooking}
      bgColor="#F6F4EF"
      highlightColor="#38724F"
      variant="bone"
    />
    <DogPawList
      {...dogFamous}
      bgColor="#F2FFF7"
      highlightColor="#02B96F"
      variant="paw"
    />
    <PaddingHelper />
    <PlanSectionComponent
      {...planSection2}
      offColor="#02B96F"
      buttonColor="bundleGreen"
      nameMargin="0rem 0 0.75rem 0"
    />
    <ReviewContainer>
      <Reviews {...reviewsSection} />
    </ReviewContainer>
  </>
);

export default UpsellBundle;
