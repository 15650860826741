import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface BannerOneProps {
  eBookTitle: string;
  eBookLabel: string;
  paperbackTitle: string;
  paperbackLabel: string;
  boughtPaperback?: boolean;
  iconUrl: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #c7c9d9;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  max-width: 30.5rem;
  width: 100%;
  align-items: center;
  @media ${tablet} {
    max-width: 21.4375rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Img = styled(DynamicImage)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const Title = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.25rem;
`;

const Label = styled(Text)`
  color: #28293d;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BannerOne: FC<BannerOneProps> = ({
  eBookTitle,
  eBookLabel,
  paperbackTitle,
  paperbackLabel,
  boughtPaperback,
  iconUrl,
}) => {
  return (
    <Container>
      <InnerContainer>
        <Img src={iconUrl} alt="icon" />
        <TextContainer>
          <Title>{boughtPaperback ? paperbackTitle : eBookTitle}</Title>
          <Label>{boughtPaperback ? paperbackLabel : eBookLabel}</Label>
        </TextContainer>
      </InnerContainer>
    </Container>
  );
};

export default BannerOne;
